<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-29 17:32:44
-->
<template>
	<div>
		<!-- 用户头 -->
		<personInfo></personInfo>
		<div class="rt dis_flex">
			<div class="rti dis_flex" v-if="specialArea != 28 && specialArea != 31 && specialArea != 30">
				{{ $t("w_0244") }}
			</div>
			<div class="rti dis_flex" v-else>
				{{ $t("MN_F_T_8") }}
			</div>
		</div>
		<!-- 表单 -->
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" status-icon class="demo-ruleForm">
			<el-row :gutter="40" class="padRow">
				<el-col :span="6">
					<el-form-item :label="$t('w_0245')"
						v-if="specialArea != 28 && specialArea != 31 && specialArea != 30" prop="upgradeMemberCode">
						<el-input v-model="ruleForm.upgradeMemberCode" :disabled="toShow"
							:placeholder="$t('w_0280')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('N_I_124')" v-else prop="upgradeMemberCode">
						<el-input v-model="ruleForm.upgradeMemberCode" :disabled="toShow"
							:placeholder="$t('w_0280')"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label="$t('w_0246')"
						v-if="specialArea != 28 && specialArea != 31 && specialArea != 30" prop="upgradeMemberName">
						<el-input v-model="ruleForm.upgradeMemberName" :placeholder="$t('w_0281')" disabled></el-input>
					</el-form-item>
					<el-form-item :label="$t('CK_KS_14')" v-else prop="upgradeMemberName">
						<el-input v-model="ruleForm.upgradeMemberName" :placeholder="$t('w_0281')" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label="$t('w_0061')" prop="deliveryWay">
						<el-select v-model="ruleForm.deliveryWay" @change="selDelivery"
							:disabled="this.userInfo.pkSettleCountry == 1" :placeholder="$t('w_0062')">
							<el-option v-for="item in deliList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label="$t('w_0063')" prop="transType">
						<el-select v-model="ruleForm.transType" :disabled="this.userInfo.pkSettleCountry == 1"
							:placeholder="$t('w_0064')">
							<el-option v-for="item in transList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6" v-if="howSure">
					<el-form-item :label="$t('ENU_PER_TY_4')" prop="makerSpaceMember">
						<el-select v-model="ruleForm.makerSpaceMember" :placeholder="$t('CK_KS_38')">
							<el-option v-for="item in queryCardList" :key="item.pkMember" :label="item.storeName"
								:value="item.pkMember">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="40" class="padRow">
				<el-col :span="6" v-if="userInfo.pkSettleCountry != 1">
					<el-form-item :label="$t('w_0100')">
						<el-select v-model="ruleForm.pkMemberStore" :placeholder="$t('w_0101')">
							<el-option v-for="item in storeList" :key="item.pkId" :label="item.storeName"
								:value="item.pkId">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6" v-if="userInfo.pkSettleCountry != 1">
					<el-form-item :label="$t('w_0102')">
						<el-select v-model="ruleForm.pkStorehouse" :placeholder="$t('w_0103')">
							<el-option v-for="item in storehouseList" :key="item.pkId" :label="item.name"
								:value="item.pkId">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<!-- 收货人信息 -->
			<!-- <div v-show="isDeliveryWay">
        <div class="xian">
          <div class="title">{{ $t('w_0065') }}</div>
          <div class="lan"
               @click="openAddress">{{ $t('w_0066') }}</div>
        </div>
      </div> -->
			<!-- 收货人信息 -->
			<div v-if="isDeliveryWay">
				<div class="xian">
					<div class="title">{{ $t("w_0065") }}</div>
					<div class="lan" @click="openAddress">{{ $t("w_0066") }}</div>
				</div>
				<el-row :gutter="40" class="padRow">
					<el-col :span="6">
						<el-form-item :label="$t('MY_ORD_10')" prop="recName">
							<el-input :maxlength="40" v-model="ruleForm.recName" :placeholder="$t('w_0067')"></el-input>
						</el-form-item>
					</el-col>
					<!-- <el-col :span="6">
            <el-form-item :label="$t('w_0052')"
                          prop="recPhone">
              <el-input v-model="ruleForm.recPhone"
              :maxlength="userInfo.pkSettleCountry == 1?11:20"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :placeholder="$t('w_0053')"></el-input>
            </el-form-item>
          </el-col> -->
					<el-col :span="6" v-if="pkCountry == 1">
						<el-form-item :label="$t('w_0052')" prop="recPhone">
							<el-input v-model="ruleForm.recPhone" :maxlength="11"
								oninput="value=value.replace(/[^\d]/g,'')" :placeholder="$t('w_0053')"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6" v-else>
						<el-form-item :label="$t('w_0052')" prop="recPhone">
							<el-input v-model="ruleForm.recPhone" :maxlength="30"
								:placeholder="$t('w_0053')"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('w_0068')" class="dizhi" prop="dizhi">
							<el-cascader v-model="ruleForm.dizhi" @change="setDizhi" :options="areaData"
								:props="regionParams" :placeholder="$t('w_0069')"></el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('S_C_27')" prop="recAddress">
							<el-input v-model="ruleForm.recAddress" maxlength="200"
								:placeholder="$t('w_0070')"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="40" class="padRow">
					<el-col :span="6">
						<el-form-item :label="$t('w_0071')">
							<el-input v-model="ruleForm.postage" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('MY_WAL_22')">
							<el-input v-model="ruleForm.remark" :placeholder="$t('w_0072')"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
		</el-form>
		<div class="nox" v-if="isDeliveryWay">
			<div class="title">{{ $t("w_0073") }}</div>
		</div>
		<div class="local" v-if="isDeliveryWay">
			<div :class="['local_i', item.pkId == moren ? 'local_a' : '']" v-for="item in addressList" :key="item.pkId">
				<el-radio v-model="moren" @input="checkRadio" :label="item.pkId">{{ }}</el-radio>
				<div class="local1">
					{{ item.recArea }}{{ item.recAddress }}({{ item.recName }} 收)
				</div>
				<div class="local2" v-show="item.isDefault == 1">
					{{ $t("w_0074") }}
				</div>
				<div class="local2 local3" v-show="item.isDefault == 0" @click="setDefaultAds(item.pkId)">
					{{ $t("w_0075") }}
				</div>
				<div class="local2" @click="changeAds(item.pkId)">
					{{ $t("w_0076") }}
				</div>
				<div class="local3" @click="delAds(item.pkId)">
					{{ $t("ENU_CONTROL_TYPE_3") }}
				</div>
			</div>
		</div>
		<!-- 商品信息 -->
		<div class="nox">
			<div class="title">{{ $t("w_0077") }}</div>
		</div>
		<el-table :data="allData" :row-style="{ height: '60px', fontSize: '15px', color: '#333333' }"
			:cell-style="{ padding: '0' }" :fit="true" :header-cell-style="{
        background: '#C8161D',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px',
      }" :row-class-name="tableRowClassName">
			<el-table-column align="center" prop="waresName" width="400" :label="$t('w_0078')">
				<template slot-scope="scope">
					<!-- <span>{{ scope.row.goodsPrice | numberToCurrency}}</span> -->
					<div class="goods">
						<div class="goods_t">
							<img :src="scope.row.cover1" alt="" />
							<div>{{ scope.row.waresName }}</div>
						</div>
						<div class="goods_tit" v-for="item in scope.row.waresItemsParamList"
							:key="item.pkWaresSpecsSku">
							<div class="goods_tit_l">
								<img :src="item.specCover" alt="" />
								<div class="goods_tit_lr">
									<div>
										{{ item.productName }}
									</div>
									<div>x {{ item.quantity }}</div>
								</div>
							</div>
							<div class="goods_tit_r">
								{{ item.skuInfo }}
							</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="goodsPrice" :label="$t('w_0079')">
				<template slot-scope="scope">
					<span>{{ scope.row.price | numberToCurrency }}</span>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="goodsAchive" :label="$t('w_0080') + '(PV)'">
				<template slot-scope="scope">
					<span>{{ scope.row.achieve | numberToCurrency }}</span>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="quantity" :label="$t('w_0081')">
			</el-table-column>
			<el-table-column align="center" prop="spyjxj" :label="$t('w_0082') + 'PV'">
				<template slot-scope="scope">
					<span>{{ scope.row.achieveAmount | numberToCurrency }}</span>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="pvxj" :label="$t('w_0093')">
				<template slot-scope="scope">
					<span>{{ scope.row.priceAmount | numberToCurrency }}</span>
				</template>
			</el-table-column>
		</el-table>
		<div class="tab_b">
			<div>
				{{ $t("w_0083") }}:{{ userInfo.currencyIcon
        }}{{ allGoodsData.priceAmount | numberToCurrency }}
			</div>
			<div>
				{{ $t("w_0071") }}:{{ userInfo.currencyIcon
        }}{{ ruleForm.postage | numberToCurrency }}
			</div>
		</div>
		<AddAddress ref="addAddress" @getAdList="getAddressList"></AddAddress>
		<div class="zhan"></div>
		<!-- 购买 -->
		<div class="tfooter">
			<div class="footer_t">
				<div class="footer_tt">
					<div class="dis_flex">
						<div class="f1">{{ $t("w_0325") }}:</div>
						<div class="f2">
							{{ userInfo.currencyIcon }}{{ totalAmont | numberToCurrency }}
						</div>
					</div>
					<div class="dis_flex">
						<div class="f1">{{ $t("ENU_TOTAL_V_1") }}:</div>
						<div class="f3">
							{{ allGoodsData.achieveAmount | numberToCurrency }}
						</div>
					</div>
				</div>
				<div class="footer_tt" v-show="
            ruleForm.recAddress &&
            ruleForm.recName &&
            ruleForm.recPhone &&
            ruleForm.nameList
          ">
					<div class="f2 f4">
						{{ $t("w_0084") }}：{{ ruleForm.nameList }}{{ ruleForm.recAddress }}
					</div>
					<div class="f4">
						{{ $t("MY_ORD_10") }}：{{ ruleForm.recName }}
						{{ ruleForm.recPhone }}
					</div>
				</div>
			</div>
			<div class="footer_b">
				<!-- 协议 -->
				<agreement v-if="specialArea != 30" :isShow="agreementShow" ref="agree" @agree="agree"></agreement>
				<!-- 协议 -->
				<div class="agreementBox flex_ac" v-if="specialArea == 30">
					<div class="agreementIcon">
						<el-checkbox v-model="agreementShow" @change="toCheck">
						</el-checkbox>
						<div class="agreementText flex">
							<span>
								<span>{{ $t("APP_ADD_19") }}</span>
								<span class="agreementDetail" v-if="userInfo.isDealer == 1"
									@click="openPop">《推广人员服务协议》</span>
								<span class="agreementDetail" v-if="userInfo.isDealer == 0"
									@click="openPop">《经销商合同》</span>
							</span>
						</div>
					</div>
				</div>

				<div v-if="specialArea == 30" class="warn">该礼包一旦购买不能退换</div>
				<div class="btn" @click="submitRegister('ruleForm')">
					{{ $t("w_0085") }}
				</div>
			</div>
		</div>
		<!-- 强制阅读协议 -->
		<mustAgreeMent :waresCodeList="waresCodeList"></mustAgreeMent>
		<Pay :isPay="isPay" :onlinePay="onlinePay" @closePay="closePay" :paramsPost="paramsPost"></Pay>
		<!-- 协议 -->
		<el-dialog class="pop" width="70%" :append-to-body="true" :visible.sync="popupShow">
			<div class="pop_box" ref="scrollContainer">
				<img v-if="userInfo.isDealer == 1" src="@/assets/images/tgxy1.jpg" alt="" />
				<img v-if="userInfo.isDealer == 0" src="@/assets/images/jxsht.jpg" alt="" />
				<span class="dialog-footer">
					<el-button type="primary" @click="popupShow = false">{{
            $t("APP_ADD_19")
          }}</el-button>
				</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import personInfo from "@/components/personInfo.vue";
	import AddAddress from "@/components/AddAddress.vue";
	import agreement from "@/components/agreement.vue";
	import {
		mapGetters
	} from "vuex";
	import * as api from "@/api/register.js";
	import {
		setTokenMi
	} from "@/util/auth";
	import Pay from "@/components/Pay.vue";
	import mustAgreeMent from "@/components/mustAgreeMent.vue";
	export default {
		components: {
			personInfo,
			AddAddress,
			agreement,
			Pay,
			mustAgreeMent,
		},
		data() {
			let validateRecPhone = (rule, value, callback) => {
				if (this.ruleForm.recPhone === "" && this.isDeliveryWay) {
					callback(new Error(this.$t("w_0053")));
				} else if (this.pkCountry == 1 && this.ruleForm.recPhone.length != 11) {
					callback(new Error(this.$t("w_0204")));
				} else if (this.pkCountry == 1 && this.ruleForm.recPhone[0] != 1) {
					callback(new Error(this.$t("w_0204")));
				} else {
					callback();
				}
			};
			let validateRecTransType = (rule, value, callback) => {
				if (this.ruleForm.transType == "" && this.isDeliveryWay) {
					callback(new Error(this.$t("w_0064")));
				} else {
					callback();
				}
			};
			return {
				popupShow: false,
				waresCodeList: [],
				isPay: false,
				paramsPost: "",
				isClick: 0,
				ruleForm: {
					upgradeMemberCode: "",
					dizhi: [],
					fwzx: "",
					upgradeMemberName: "",
					postage: "",
					recName: "",
					recPhone: "",
					recAddress: "",
					deliveryWay: "",
					transType: "",
				},
				rules: {
					upgradeMemberCode: [{
						required: true,
						validator: this.upgradeMemberCodePass,
						trigger: "blur",
					}, ],
					fwzx: [{
						required: true,
						message: "请输入活动名称",
						trigger: "change"
					}, ],
					gradeName: [{
						required: true,
						message: this.$t("w_0086"),
						trigger: "change"
					}, ],
					upgradeMemberName: [{
						required: true,
						message: this.$t("w_0097"),
						trigger: "blur"
					}, ],
					recName: [{
						required: true,
						message: this.$t("w_0087"),
						trigger: "blur"
					}, ],
					recPhone: [{
						validator: validateRecPhone,
						trigger: "blur"
					}],
					recAddress: [{
						required: true,
						message: this.$t("w_0070"),
						trigger: "blur"
					}, ],
					deliveryWay: [{
						required: true,
						message: this.$t("w_0062"),
						trigger: "change",
					}, ],
					makerSpaceMember: [{
						required: true,
						message: this.$t('CK_KS_38'),
						trigger: 'change',
					}, ],
					// transType: [
					//   {
					//     required: true,
					//     message: '请选择运输方式',
					//     trigger: 'change',
					//   },
					// ],
					transType: [{
						validator: validateRecTransType
					}],
				},
				addressList: [],
				moren: "",
				allGoodsData: [],
				allData: [],
				agreementShow: false,
				countryList: [],
				transList: [{
						value: 1,
						label: "陆运",
					},
					{
						value: 2,
						label: "空运",
					},
					{
						value: 3,
						label: "海运",
					},
				],
				deliList: [],
				bankLists: [],
				areaData: [],
				regionParams: {
					label: "name",
					value: "id",
					children: "children",
					expandTrigger: "hover",
				},
				totalPrice: 0,
				totalAchive: 0,
				isLoading: "",
				isDeliveryWay: true,
				shoppingArr: "",
				totalAmont: 0,
				specialArea: "",
				storeList: [],
				storehouseList: [],
				toShow: false,
				checkObj: [],
				onlinePay: true,
				pkCountry: "",
				howSure: false,
				queryCardList: [],
			};
		},
		computed: {
			...mapGetters(["userInfo"]),
		},
		mounted() {
			// // 数据源
			// this.allGoodsData = JSON.parse(localStorage.getItem('allGoods'))
			// this.allGoodsData[0].orderItemsParams.forEach((item) => {
			//   item.goodsPrice = 0
			//   item.goodsAchive = 0
			//   item.waresItemsParamList.forEach((ctem) => {
			//     item.goodsPrice += parseFloat(ctem.priceTotal)
			//     item.goodsAchive += parseFloat(ctem.achieveTotal)
			//   })
			// })
			// let totalPrice = 0
			// let totalAchive = 0
			// this.allGoodsData[0].orderItemsParams.forEach((item) => {
			//   totalPrice += parseFloat(item.goodsPrice * item.quantity)
			//   totalAchive += parseFloat(item.goodsAchive * item.quantity)
			// })
			// this.totalPrice = totalPrice
			// this.totalAchive = totalAchive
			// this.allData = this.allGoodsData[0].orderItemsParams
			this.specialArea = this.$route.query.specialArea;
			if (
				this.specialArea == 12 ||
				this.specialArea == 22 ||
				this.specialArea == 14 ||
				this.specialArea == 28 ||
				this.specialArea == 31 ||
				this.specialArea == 30
			) {
				this.toShow = true;
				this.$forceUpdate();
			}
			if (this.specialArea == 30) {
				this.agreementShow = true;
				this.$forceUpdate();
			}
			this.pkCountry = parseInt(localStorage.getItem("pkCountry"));
			// // 服务中心赋值
			this.ruleForm.upgradeMemberCode = this.userInfo.memberCode;
			this.getUserName();
			if (this.userInfo.pkSettleCountry != 1) {
				//获取会员店铺
				this.getStoreList();
				//获取仓库
				this.getStorehouseList();
			}
			// // 获取地址
			// this.getAddressList()
			// // 获取运输方式
			// this.getTransList()
			// // 获取发货方式
			// this.getDeliveryList()
			// this.getAllArea()
			// // 获取会员等级
			// // this.getMember()
			// 数据源
			this.shoppingArr = JSON.parse(localStorage.getItem("allGoods"));
			// 获取购物车详情
			this.getCatDetail();
			// 幂等性
			api.generate().then((res) => {
				setTokenMi(res.msg);
			});
			if (this.userInfo.pkSettleCountry == 1) {
				this.ruleForm.transType = 1;
				this.ruleForm.deliveryWay = 1;
			}
		},
		methods: {
			getQueryCard() {
				api
					.queryCard({
						specialArea: this.specialArea,
						upgradeMemberCode: this.ruleForm.upgradeMemberCode,
					})
					.then((res) => {
						this.queryCardList = res.data;
					});
			},
			openPop() {
				this.popupShow = true;
			},
			toCheck(e) {
				this.agreementShow = e;
			},
			getStoreList() {
				api.storeList().then((res) => {
					this.storeList = res.rows;
				});
			},
			getStorehouseList() {
				api.storehouseList().then((res) => {
					this.storehouseList = res.rows;
				});
			},
			// 升级编号获取name
			upgradeMemberCodePass(rule, value, callback) {
				if (!value) {
					callback(new Error("请输入复购编号"));
				} else {
					api
						.upgRel({
							upgradeMemberCode: value,
						})
						.then((res) => {
							if (res.code == 200) {
								this.ruleForm.upgradeMemberName = res.data.upgradeMemberName;
								if (this.howSure) {
									this.getQueryCard();
								}
								// this.getMember()
								this.$forceUpdate();
								callback();
							}
						})
						.catch((err) => {
							callback(new Error(err));
						});
				}
			},
			getCatDetail() {
				api
					.shoppingWaresDetail({
						shoppingIds: this.shoppingArr,
						specialArea: this.specialArea,
					})
					.then((res) => {
						this.allGoodsData = res.data;
						this.waresCodeList = res.data.orderItemsParams.map((item) => {
							return item.waresCode;
						});
						this.allGoodsData.specialArea = this.specialArea;
						this.allData = this.allGoodsData.orderItemsParams;
						this.totalAmont = this.allGoodsData.priceAmount;
						// 服务中心赋值
						this.ruleForm.fwzx = this.userInfo.memberCode;
						let orderItemsParams = [];
						this.waresCodeList.forEach((item) => {
							orderItemsParams.push({
								waresCode: item,
							});
						});
						this.checkObj = {
							specialArea: this.specialArea,
							orderItemsParams: orderItemsParams,
						};

						// 判断特殊商品
						if (this.specialArea == 3) {
							this.checkGoods();
						}
						// let howSure = this.allData.some((item) => item.isMakerGift == 4);
						// this.howSure = howSure;
						// if(this.howSure){
						//     this.getQueryCard();
						//   }
						// 获取地址
						this.getAddressList();
						// 获取运输方式
						this.getTransList();
						// 获取发货方式
						this.getDeliveryList();
						// 获取省市县
						this.getAllArea();
						// 获取会员等级
						// this.getMember()
					});
			},
			checkGoods() {
				// 判断特殊商品
				api.makerList().then((res) => {
					if (res.data.length > 0) {
						let isYes = 0;
						res.data.forEach((item) => {
							if (this.allData.some((ctem) => ctem.waresCode == item)) {
								isYes++;
							}
						});
						if (isYes != 0) {
							this.howSure = true;
							if (this.howSure) {
								this.getQueryCard();
							}
						}
					}
				});
			},
			getUserName() {
				api
					.upgRel({
						upgradeMemberCode: this.ruleForm.upgradeMemberCode,
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm.upgradeMemberName = res.data.upgradeMemberName;
							this.$forceUpdate();
						}
					});
			},
			selDelivery(e) {
				if (e == 2 || e == 3) {
					this.isDeliveryWay = false;
				} else {
					this.isDeliveryWay = true;
				}
			},
			openLoading() {
				this.isLoading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
			},
			resetAll() {
				this.ruleForm.parent = "";
				this.ruleForm.upgradeMemberName = "";
				this.ruleForm.deliveryWay = "";
				this.ruleForm.transType = "";
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
			},
			setDizhi(e, val) {
				let arr = this.flatten(this.areaData);
				let nameList = "";
				arr.forEach((item) => {
					this.ruleForm.dizhi.forEach((ctem) => {
						if (ctem == item.id) {
							nameList += item.name;
						}
					});
				});
				this.ruleForm.nameList = nameList;
				this.allGoodsData.recProvince = this.ruleForm.dizhi[0];
				this.allGoodsData.recCity = this.ruleForm.dizhi[1];
				this.allGoodsData.recCounty = this.ruleForm.dizhi[2] || "";
				this.allGoodsData.recName = this.ruleForm.recName;
				this.allGoodsData.recPhone = this.ruleForm.recPhone;
				this.allGoodsData.recAddress = this.ruleForm.recAddress;
				this.allGoodsData.deliveryWay = this.ruleForm.deliveryWay;
				this.allGoodsData.transType = this.ruleForm.transType;
				this.getPostAge();
			},
			closePay(val) {
				if (val) {
					this.isPay = false;
					this.$router.push({
						path: "/vipCenter",
						query: {
							id: 1
						},
					});
				} else {
					this.$confirm(this.$t("w_0090") + "?")
						.then((_) => {
							this.isPay = false;
							this.$router.push({
								path: "/vipCenter",
								query: {
									id: 1
								},
							});
						})
						.catch((_) => {});
				}
			},
			// 注册
			submitRegister(formName) {
				if (this.specialArea == 31) {
					if (this.totalAmont < 1000) {
						this.$message({
							message: "购买商品总价格不能低于1000元",
							type: "warning",
						});
						return;
					}
				}
				let deleteList = [];
				this.shoppingArr.forEach((item) => {
					deleteList.push({
						shoppingId: item,
					});
				});
				if (!this.agreementShow) {
					this.$message({
						message: "请阅读并同意协议",
						type: "warning",
					});
					return;
				}
				if (this.isDeliveryWay && !this.ruleForm.recPhone) {
					this.$message({
						message: this.$t("w_0092"),
						type: "warning",
					});
					return;
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.openLoading();
						let obj = Object.assign({}, this.allGoodsData, this.ruleForm, {
							deleteList: deleteList,
						});

						api
							.confirmOthOrder(obj)
							.then((res) => {
								this.isLoading.close();
								if (this.specialArea == 3 || this.specialArea == 22) {
									api.checkPay(this.checkObj).then((response) => {
										if (res.code == 200) {
											this.isPay = true;
											this.onlinePay = response.data;
											this.paramsPost = res.data;
										}
									});
								} else {
									if (res.code == 200) {
										this.isPay = true;
										this.paramsPost = res.data;
									}
								}
							})
							.catch((err) => {
								this.isLoading.close();
							});
					} else {
						return false;
					}
				});
				// let obj = Object.assign({}, this.allGoodsData[0], this.ruleForm)
				// console.log(
				//   '%c [ obj ]-498',
				//   'font-size:13px; background:#88187e; color:#cc5cc2;',
				//   obj
				// )
			},
			flatten(arr) {
				return arr.reduce((result, item) => {
					return result.concat(
						item,
						Array.isArray(item.children) ? this.flatten(item.children) : []
					);
				}, []);
			},
			checkRadio() {
				this.$set(this.ruleForm, "dizhi", []);
				let arr = [];
				this.addressList.forEach((item) => {
					if (item.pkId == this.moren) {
						this.ruleForm.recName = item.recName;
						this.ruleForm.nameList = item.recArea;
						this.ruleForm.recPhone = item.recPhone;
						this.ruleForm.recAddress = item.recAddress;
						arr = [
							item.recProvince + "",
							item.recCity + "",
							(item.recCounty || "") + "",
						];
					}
				});
				arr = arr.filter((s) => {
					return s && s.trim();
				});

				this.$set(this.ruleForm, "dizhi", arr);
				this.allGoodsData.deliveryWay = this.ruleForm.deliveryWay;
				this.allGoodsData.transType = this.ruleForm.transType;
				this.allGoodsData.recProvince = this.ruleForm.dizhi[0];
				this.allGoodsData.recCity = this.ruleForm.dizhi[1];
				this.allGoodsData.recCounty = this.ruleForm.dizhi[2];

				this.allGoodsData.recName = this.ruleForm.recName;
				this.allGoodsData.recPhone = this.ruleForm.recPhone;
				this.allGoodsData.recAddress = this.ruleForm.recAddress;
				this.getPostAge();
				this.$forceUpdate();
			},
			getPostAge() {
				let deleteList = [];
				this.shoppingArr.forEach((item) => {
					deleteList.push({
						shoppingId: item,
					});
				});
				this.allGoodsData.deliveryWay = this.ruleForm.deliveryWay;
				this.allGoodsData.transType = this.ruleForm.transType;
				this.allGoodsData.deleteList = deleteList;
				api.queryPostage(this.allGoodsData).then((res) => {
					this.ruleForm.postage = Number(res.data.postage).toFixed(2);
					this.totalAmont =
						parseFloat(this.allGoodsData.priceAmount) +
						parseFloat(res.data.postage);
				});
			},
			// getMember() {
			//   this.allGoodsData.upgradeMemberCode = this.ruleForm.upgradeMemberCode
			//   api.memLevel(this.allGoodsData).then((res) => {
			//     if (res.data.isEnough) {
			//       this.ruleForm.gradeName = res.data.gradeName
			//     } else {
			//       this.isLoading.close()
			//     }
			//   })
			// },
			getTransList() {
				api.transportList().then((res) => {
					this.transList = res.data;
				});
			},
			getDeliveryList() {
				api.deliveryList().then((res) => {
					this.deliList = res.data;
				});
			},
			delAds(id) {
				this.$confirm(this.$t("w_0088") + "?", "", {
					confirmButtonText: this.$t("w_0035"),
					cancelButtonText: this.$t("ENU_P_TYPE0"),
					type: "warning",
				}).then(() => {
					api.delAddress(id).then((res) => {
						this.$message({
							type: "success",
							message: this.$t("w_0089") + "!",
						});
						this.getAddressList();
					});
				});
			},
			getAllArea() {
				api.allArea().then((res) => {
					this.areaData = res.data;
				});
			},
			changeAds(id) {
				this.$refs.addAddress.isShowAddress(true, this.areaData, id);
			},
			setDefaultAds(id) {
				api.addressDefault(id).then((res) => {
					this.getAddressList();
				});
			},
			// 同意协议
			agree(val) {
				this.agreementShow = val;
			},
			openAddress() {
				this.$refs.addAddress.isShowAddress(true, this.areaData);
			},
			getAddressList() {
				api.addressList({}).then((res) => {
					this.addressList = res.data;
					this.addressList.forEach((item) => {
						if (item.isDefault == 1) {
							this.moren = item.pkId;
							this.checkRadio();
						}
					});
					// this.moren = this.addressList[0].pkId
				});
			},
			// 斑马线
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "warning-row";
				} else if (rowIndex % 2 == 1) {
					return "success-row";
				}
				return "";
			},
		},
	};
</script>

<style lang="scss" scoped>
	.dis_flex {
		display: flex;
		align-items: center;
	}

	.rt {
		border-bottom: 5px solid rgba(7, 7, 7, 0.05);
		padding: 45px 60px 15px 60px;

		.rti {
			margin-right: 80px;
		}

		.zc {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			margin-right: 20px;
		}

		.zca {
			font-size: 18px;
			font-weight: bold;
			color: #b42b2a;
		}
	}

	.demo-ruleForm {
		margin-top: 20px;
		overflow: hidden;
	}

	.padRow {
		padding: 0 60px;
	}

	::v-deep .el-input__suffix {
		right: auto;

		.el-input__validateIcon {
			position: absolute;
		}
	}

	::v-deep .el-select {
		width: 100%;
	}

	::v-deep .el-select .el-input__suffix {
		right: 0;
	}

	::v-deep .el-icon-circle-check {
		color: #009900;
	}

	::v-deep .el-form-item__label {
		font-size: 14px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 28px;
	}

	.xian {
		padding: 10px 60px;
		border-bottom: 5px solid rgba(7, 7, 7, 0.05);
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.nox {
		padding: 10px 60px;
	}

	.title {
		font-size: 18px;
		font-weight: 600;
		color: #333333;
	}

	.lan {
		font-size: 18px;
		font-weight: 400;
		color: #3499ff;
		cursor: pointer;
	}

	.local {
		padding: 0px 60px;

		.local_i {
			display: flex;
			align-items: center;
			padding: 30px 90px;
		}

		.local_a {
			background: #eeeeee;
			border-radius: 8px 8px 8px 8px;
		}

		.local1 {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			margin-right: 50px;
		}

		.local2 {
			font-size: 14px;
			font-weight: 400;
			color: #666666;
			margin-right: 70px;
			cursor: pointer;
		}

		.local3 {
			font-size: 14px;
			font-weight: 400;
			color: #f92a28;
			cursor: pointer;
		}
	}

	::v-deep .el-radio {
		margin-right: 0;
	}

	.guoqi {
		.el-select-dropdown__item {
			height: 26px;
			// text-align: center;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		}

		.shortName {
			margin-left: 10px;
		}
	}

	.guoqikang {
		::v-deep input {
			// height: 100px;
		}
	}

	.imgSize {
		height: 20px;
	}

	.dizhi {
		::v-deep .el-cascader {
			width: 100%;
		}
	}

	::v-deep .el-cascader .el-input__suffix {
		right: 0;
	}

	.zhan {
		height: 250px;
	}

	.tfooter {
		position: fixed;
		width: 100%;
		bottom: 0;
		z-index: 10;

		.footer_t {
			padding: 5px 60px 15px 60px;
			background: #ededed;

			.footer_tt {
				justify-content: right;
				display: flex;
				margin-top: 10px;
			}
		}

		.footer_b {
			text-align: right;
			padding: 10px 60px 10px 60px;
			background: #fff;
		}
	}

	.f1 {
		font-size: 14px;
		font-weight: 400;
		color: #333333;
	}

	.f2 {
		font-size: 18px;
		font-weight: 500;
		color: #f43c3a;
		margin-right: 40px;
	}

	.f3 {
		font-size: 14px;
		font-weight: 400;
		color: #384a8a;
	}

	.f4 {
		font-size: 14px;
		font-weight: 400;
		color: #666;
	}

	.warn {
		color: #d61820;
		font-size: 14px;
		padding-top: 6px;
	}

	.btn {
		background: #d61820;
		border-radius: 8px 8px 8px 8px;
		padding: 12px 40px;
		display: inline-block;
		color: #fff;
		font-size: 18px;
		margin-top: 10px;
		cursor: pointer;
	}

	.goods {
		text-align: left;
		padding-top: 20px;
		padding-left: 50px;
	}

	.goods_t {
		display: flex;
		font-size: 14px;
		font-weight: 400;
		color: #333333;

		// justify-content: center;
		img {
			width: 88px;
			height: 88px;
			margin-right: 10px;
		}
	}

	.goods_tit {
		font-size: 12px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		margin-top: 10px;
		display: flex;

		img {
			width: 35px;
			height: 35px;
			margin-right: 10px;
		}

		.goods_tit_l {
			flex: 1;
			display: flex;
			align-items: center;
		}

		.goods_tit_r {
			width: 200px;
		}
	}

	.tab_b {
		text-align: right;
		font-size: 12px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		padding: 20px 60px 0 0;
	}

	.agreementBox {
		.agreementIcon {
			display: flex;
			margin-left: 10px;
			justify-content: flex-end;
		}

		.agreementText {
			margin-left: 10px;
			font-size: 14px;
			max-width: 600px;
			display: flex;
			flex-wrap: wrap;

			.agreementDetail {
				color: #72deed;
				font-weight: bold;
				font-size: 14px;
				cursor: pointer;
			}
		}
	}

	.pop {
		z-index: 20000 !important;
		text-align: left;

		.pop_box {
			max-height: 65vh;
			overflow-y: scroll;
			padding: 0rem 0.4rem 0.8rem 0.4rem;
			text-align: center;

			img {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		.pop_title {
			width: 100%;
			height: 1.4rem;
			line-height: 1.4rem;
			font-size: 16px;
			text-align: center;
		}

		.pop_title1 {
			width: 100%;
			font-size: 16px;
			text-align: center;
			margin-bottom: 20px;
			font-weight: 500;
		}

		.pop_title2 {
			width: 100%;
			font-size: 16px;
			text-align: center;
			margin-top: 20px;
			font-weight: 500;
		}
	}
</style>